import React from "react"
import SbEditable from "storyblok-react"

import { StyledRichText } from "components/Common/RichTextRenderer"

import * as Styled from "./styles"

export default function SectionTwoColumns({ blok }) {
  return (
    <SbEditable content={blok}>
      <Styled.Section>
        <StyledRichText data={blok?.column_1?.[0]?.body} />
        <StyledRichText data={blok?.column_2?.[0]?.body} />
      </Styled.Section>
    </SbEditable>
  )
}
