import React, { useState, useMemo } from "react"
import SbEditable from "storyblok-react"
import { getFluidGatsbyImage } from "gatsby-storyblok-image"
import Img from "gatsby-image"
import Carousel from "react-multi-carousel"

import theme from "utils/theme"

import "react-multi-carousel/lib/styles.css"
import "./carousel.css"

import {
  ConferenceParagraph,
  ConferenceSubjects,
  ConferenceTitle,
  PreviousWrapper,
  PreviousConferencesHolder,
  PreviousConferencesCarousel,
  ExploreButton,
  CarouselDot,
  CarouselImageInfo,
  SectionTitle,
  ConferenceSubjectsItem,
} from "./PreviousConferences.styles"

export default function PreviousConferences({ blok }) {
  const [currentSlide, setCurrentSlide] = useState(1)

  const getImageFluid = image =>
    image && useMemo(() => getFluidGatsbyImage(image, { maxWidth: 500 }), [])

  const previousSlide = (previousSlide, _ref) => {
    var currentSlide = _ref.currentSlide
    _ref.onMove
    setCurrentSlide(currentSlide - 3)
  }

  return (
    <SbEditable content={blok}>
      <div style={{ display: "block", maxWidth: "100%", padding: "2.5rem" }}>
        <SectionTitle>{blok.section_title}</SectionTitle>
        <PreviousWrapper>
          <PreviousConferencesHolder>
            <ConferenceTitle>{blok.previous_conferences_title}</ConferenceTitle>
            <ConferenceParagraph theme={theme}>
              {blok.previous_conferences_subtitle}
            </ConferenceParagraph>
            <ConferenceSubjects content={blok.conference_institutions}></ConferenceSubjects>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ExploreButton href={blok.cta?.[0]?.url?.url} theme={theme}>
                {blok.cta[0].text}
              </ExploreButton>
            </div>
          </PreviousConferencesHolder>
          <PreviousConferencesCarousel>
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              afterChange={(previousSlider, _ref) => previousSlide(previousSlider, _ref)}
              autoPlaySpeed={3000}
              centerMode={false}
              customDot={<CarouselDot />}
              className=""
              dotListClass="dots"
              focusOnSelect={false}
              infinite
              draggable={false}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={true}
              // customButtonGroup={<Arrow />}
              renderDotsOutside={false}
              showDots
              sliderClass=""
              slidesToSlide={1}
              swipeable
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 993,
                  },
                  items: 2,
                  partialVisibilityGutter: 170,
                },
                mobile: {
                  breakpoint: {
                    max: 600,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 992,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              showDots={true}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {blok.slider.length > 0 &&
                blok.slider.map((slide, index) =>
                  index == 0 ? (
                    <div
                      key={slide._uid}
                      style={{
                        width: currentSlide == blok.slider.length ? "90%" : "100%",
                        marginLeft: currentSlide == blok.slider.length ? "5%" : "0",
                        transition: "all ease-in-out 0.3s",
                        position: "relative",
                      }}
                    >
                      <Img fluid={getImageFluid(slide.image)} width="100%" />
                      {currentSlide == 1 && (
                        <CarouselImageInfo>
                          <p>{slide.conference_date_and_location}</p>
                          <h6>{slide.conference_name}</h6>
                        </CarouselImageInfo>
                      )}
                    </div>
                  ) : (
                    <div
                      key={slide._uid}
                      style={{
                        width: currentSlide == index ? "90%" : "100%",
                        marginLeft: currentSlide == index ? "5%" : "0",
                        transition: "all ease-in-out 0.3s",
                        position: "relative",
                      }}
                    >
                      <Img fluid={getImageFluid(slide.image)} width="100%" />
                      {currentSlide == index + 1 && (
                        <CarouselImageInfo>
                          <p>{slide.conference_date_and_location}</p>
                          <h6>{slide.conference_name}</h6>
                        </CarouselImageInfo>
                      )}
                    </div>
                  )
                )}
            </Carousel>
          </PreviousConferencesCarousel>
        </PreviousWrapper>
      </div>
    </SbEditable>
  )
}
