import styled from "styled-components";
import media from "utils/media";

export const ImportantDatesWrapper = styled.div`
  width: 100%;
  text-align: left;
  margin: 5rem 0;

  ${media.greaterThan("xs")`
    width: 100%;
    padding-left: 1.563rem;
    padding-right: 1.563rem;
  `};

  ${media.greaterThan("sm")`
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  `};

  ${media.greaterThan("md")`
    padding-left: 0;
    padding-right: 0;
  `};
`;

export const ImportantDatesTitle = styled.h4`
  font-size: 2.5rem;
  margin-bottom: 2.5rem;
  line-height: 1.2;
  text-align: center;
`;
