import React, { useMemo } from "react";
import SbEditable from "storyblok-react";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";

import theme from "utils/theme";

import { Container } from "components/Grid";

import {
  NewsWrapper,
  NewsImageHolder,
  ConferencalNews,
  ConferenceTitle,
  ConferenceParagraph,
  ConferenceSubjects,
} from "./News.styles";

export default function NewsSection({ blok }) {
  const getImageFluid = (image) =>
    image && useMemo(() => getFluidGatsbyImage(image, { maxWidth: 400 }), []);

  return (
    <SbEditable content={blok}>
      <Container>
        <NewsWrapper>
          <NewsImageHolder as={Img} fluid={getImageFluid(blok.image)} />
          <ConferencalNews>
            <ConferenceTitle>{blok.title}</ConferenceTitle>
            <ConferenceParagraph theme={theme}>
              {blok.description}
            </ConferenceParagraph>
            <ConferenceSubjects
              data={blok.additional_info}
              theme={theme}
            ></ConferenceSubjects>
          </ConferencalNews>
        </NewsWrapper>
      </Container>
    </SbEditable>
  );
}
