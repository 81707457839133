import styled from "styled-components"
import media from "utils/media"

export const InfoWrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  margin: 2.5rem 0;

  ${media.greaterThan("xs")`
    padding-left: 1.563rem;
    padding-right: 1.563rem;
    flex-direction: column;
  `};

  ${media.greaterThan("sm")`
    padding-left: 0;
    padding-right: 0;
    flex-direction: row;
  `};
`

export const ConferenceInfoTitle = styled.p`
  width: 50%;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.9rem;
  color: ${({ theme }) => theme.colors["text_primary"]};

  ${media.greaterThan("xs")`
    font-size: 1.75rem;
    width: 100%;
    margin-bottom: 2.188rem;
  `};

  ${media.greaterThan("sm")`
    font-size: 2.5rem
    margin-bottom: 0;
    width: 50%;
  `};

  small {
    margin-top: 1rem;
    display: block;
    font-weight: 500;
  }
`

export const InfoHolder = styled.div`
  width: 50%;

  > h4 {
    margin-bottom: 1rem;
  }

  > div {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;

    ${media.greaterThan("sm")`
      flex-direction: row;
    `};
  }

  ${media.greaterThan("xs")`
    width: 100%;
  `};

  ${media.greaterThan("sm")`
    width: 50%;
  `};
`

export const SingleInfo = styled.div``

export const InfoTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.688rem;
  color: ${({ theme }) => theme.colors["text_primary"]};
`

export const InfoParagraph = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors["dark"]};
`
