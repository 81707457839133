import React, { useState } from "react"
import SbEditable from "storyblok-react"
import { Link } from "gatsby"

import theme from "utils/theme"

import Drawer from "components/Drawer/Drawer"

import {
  HeaderLogo,
  HeaderWrapper,
  HeaderPlaceholder,
  MenuHolder,
  MenuIcon,
  MenuLink,
} from "./Header.styles"

export default function Header({ data }) {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false)

  const toggleDrawer = () => setIsDrawerOpened(!isDrawerOpened)

  return (
    <SbEditable content={data}>
      <div>
        <HeaderPlaceholder />

        <HeaderWrapper>
          <Link to="/">
            <HeaderLogo src={data?.logo?.filename} alt="ICSEng-logo" />
          </Link>

          <MenuHolder theme={theme}>

            {(data?.links || []).map(link => (
              <MenuLink to={link.url} theme={theme} as={Link} activeClassName="active">
                {link?.label}
              </MenuLink>
            ))}
          </MenuHolder>

          <MenuIcon className={isDrawerOpened ? "open" : ""} onClick={toggleDrawer}>
            <span />
            <span />
            <span />
          </MenuIcon>

          <Drawer links={data?.links} visible={isDrawerOpened} />
        </HeaderWrapper>
      </div>
    </SbEditable>
  )
}
