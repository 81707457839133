import styled from 'styled-components'
import media from "utils/media";
import Img from 'gatsby-image'

export const ClientsWrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2.5rem 0;

  ${media.greaterThan("xs")`
    padding-left: 1.563rem;
    padding-right: 1.563rem;
  `};

  ${media.greaterThan("sm")`
    padding-left: 0;
    padding-right: 0;
  `};
`

export const ClientsSectionTitle = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors['text_primary']};
`

export const ClientsSectionSubTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;
  color: ${({ theme }) => theme.colors['text_secondary']};
  margin-bottom: 1.25rem;
`

export const LogosHolder = styled.div`
  display: flex;

  ${media.greaterThan("xs")`
    flex-direction: column;
  `};

  ${media.greaterThan("sm")`
    flex-direction: column;
  `};

  ${media.greaterThan("md")`
    flex-direction: row;
  `};
`

export const LogoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const LogoCard = styled.div`
  width: 10.625rem;
  height: 6.3rem;
  box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
  border-radius: 1rem;
  padding: 1.875rem 2.063rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.75rem;
`

export const LogoImage = styled(Img)`
  width: 100%;
`