import styled from "styled-components";
import media from "utils/media";

export const PublicationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 0;

  ${media.greaterThan("xs")`
    padding-left: 0rem;
    padding-right: 0rem;
  `};

  ${media.greaterThan("sm")`
    padding-left: 0;
    padding-right: 0;
  `};
`

export const PublicationTitle = styled.h3`
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
  color: ${({ theme }) => theme.colors['text_primary']};
  margin-bottom: 2.188rem;
`;

export const PublicationsSubTitle = styled.p`
  font-size: 1.25rem;
  line-height: 2.188rem;
  text-align: center;

  ${media.greaterThan("xs")`
    width: 90%;
  `};

  ${media.greaterThan("sm")`
    width: 90%;
  `};

  ${media.greaterThan("md")`
    width: 80%;
  `};
`;

export const PublicationsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3.125rem;

  ${media.greaterThan("xs")`
    justify-content: space-around;
  `};

  ${media.greaterThan("sm")`
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
  `};

  ${media.greaterThan("md")`
    justify-content: space-between;
  `};
`