import styled from "styled-components";
import Img from "gatsby-image"

export const HeroSection = styled(Img)`
    width: 100%;
    min-height: 30rem;
    max-height: 100rem;
    background-repeat: no-repeat;
    background-size: cover;
`;
