import styled from 'styled-components'

export const SingleDateContainer = styled.p`
  color: ${({ theme }) => theme.colors['text_secondary']};
  font-size: 1.25rem;
  line-height: 150%;

  span {
    font-weight: bold;
  }
`