import Page from "./page"
import Hero from "./Hero"
import Link from "./Common/Link"
import InfoSection from "./InfoSection"
import News from "./News/News"
import ConferencePurpose from "./ConferencePurpose/ConferencePurpose"
import ImportantDates from "./ImportantDates/ImportantDates"
import PreviousConferences from "./PreviousConferences/PreviousConferences"
import Publications from "./Publications/Publications"
import ClientsSection from "./Clients/Clients"
import ConferenceDetailsSection from "./ConferenceDetailsSection/ConferenceDetailsSection"
import SectionRichText from "./SectionRichText"
import SectionTwoColumns from "./SectionTwoColumns"

export default {
  page: Page,
  hero: Hero,
  link: Link,
  info_section: InfoSection,
  news: News,
  conference_purpose: ConferencePurpose,
  important_dates: ImportantDates,
  previous_conferences: PreviousConferences,
  publications_section: Publications,
  clients_section: ClientsSection,
  conference_details_section: ConferenceDetailsSection,

  section_rich_text: SectionRichText,
  section_two_column_layout: SectionTwoColumns,
}
