import React from "react"
import { Link } from "gatsby"

import theme from "utils/theme"

import { DrawerContainer, MenuHolder, MenuLink } from "./Drawer.styles"

export default function Drawer({ visible, links = [] }) {
  return (
    <DrawerContainer style={{ width: visible ? "100%" : "0%" }}>
      <MenuHolder>
        {links.map(link => (
          <MenuLink to={link.url} theme={theme} as={Link}  activeClassName="active">
            {link?.label}
          </MenuLink>
        ))}
      </MenuHolder>
    </DrawerContainer>
  )
}
