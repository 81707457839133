import React from "react"
import styled, { ThemeProvider } from "styled-components"

import { theme } from "utils"

import Footer from "components/Footer/Footer"
import Header from "components/Header/Header"

import GlobalStyle from "./GlobalStyles"

const Main = styled.main`
  min-height: calc(100vh - 225px);
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Layout = ({ contentHeader, contentFooter, children }) => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Header data={contentHeader} />
      <Main>{children}</Main>
      <Footer data={contentFooter} />
    </ThemeProvider>
    <GlobalStyle />
  </React.Fragment>
)

export default Layout