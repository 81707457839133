import React, { useMemo } from "react"
import SbEditable from "storyblok-react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-storyblok-image"

import { Container } from "components/Grid"

import { HeroSection } from "./Hero.styles"

export default function Hero({ blok }) {
  const getImageFluid = image =>
    image && useMemo(() => getFluidGatsbyImage(image, { maxWidth: 1500 }), [])

  return (
    <SbEditable content={blok}>
      <HeroSection as={Img} fluid={getImageFluid(blok.bg_image)}>
        <Container />
      </HeroSection>
    </SbEditable>
  )
}
