import React from 'react'
import Img from 'gatsby-image'

import { PublicationCardHeading, PublicationCardSubTitle, PublicationCardTitle, PublicationCardWrapper, PublicationImage } from './PublicationCard.styles'
import theme from 'utils/theme';

export default function PublicationCard(props) {

  const { title, subTitle, image, href } = props

  return (
    <PublicationCardWrapper href={href} target="_blank">
      <PublicationCardHeading>
        <PublicationCardTitle theme={theme}>{title}</PublicationCardTitle>
        <PublicationCardSubTitle theme={theme}>{subTitle}</PublicationCardSubTitle>
      </PublicationCardHeading>
      <PublicationImage as={Img} fluid={image} />
    </PublicationCardWrapper>
  )
}