import React from "react"
import SbEditable from "storyblok-react"

import { StyledRichText } from "components/Common/RichTextRenderer"
import { Container } from "components/Grid"

export default function SectionRichText({ blok }) {
  return (
    <SbEditable content={blok}>
      <Container
        style={{
          width: "100%",
        }}
      >
        <StyledRichText data={blok?.body} />
      </Container>
    </SbEditable>
  )
}
