import styled from "styled-components";
import media from "utils/media";
import Img from "gatsby-image";

import { RichText } from "../Common/RichTextRenderer";

export const NewsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 2.5rem 0;

  ${media.greaterThan("xs")`
    width: 100%;
    padding-left: 1.563rem;
    padding-right: 1.563rem;
    flex-direction: column;
  `};

  ${media.greaterThan("sm")`
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  `};

  ${media.greaterThan("md")`
    flex-direction: row;
  `};
`;

export const NewsImageHolder = styled(Img)`
  margin: 2.5rem 0;
  width: 45%;

  ${media.greaterThan("xs")`
    width: 100%;
  `};

  ${media.greaterThan("sm")`
    width: 50%;
    height: auto;
    object-fit: contain;
  `};

  ${media.greaterThan("lg")`
    width: 45%;
  `};
`;

export const ConferencalNews = styled.div`
  width: 50%;

  ${media.greaterThan("xs")`
    width: 100%;
  `};

  ${media.greaterThan("sm")`
    width: 47%;
  `};

  ${media.greaterThan("lg")`
    width: 50%;
  `};
`;

export const ConferenceTitle = styled.h3`
  font-size: 2.5rem;
`;
export const ConferenceParagraph = styled.p`
  font-size: 1.25rem;
  line-height: 2.188rem;
  color: ${({ theme }) => theme.colors["text_primary"]};
  font-weight: 500;
  margin-bottom: 1.875rem;

  ${media.greaterThan("xs")`
    font-size: 1.25rem;
  `};

  ${media.greaterThan("sm")`
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
    line-height: 1.563rem;
  `};

  ${media.greaterThan("lg")`
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    line-height: 2.188rem;
  `};
`;

export const ConferenceSubjects = styled(RichText)`
  font-size: 1.25rem;
  line-height: 2.188rem;
  color: ${({ theme }) => theme.colors["text_secondary"]};
  font-style: italic;

  ul,
  ol li {
    line-height: 60px !important;
  }

  p {
    line-height: 1.5rem;
  }

  ${media.greaterThan("xs")`
  font-size: 1.25rem;
`};

  ${media.greaterThan("sm")`
  font-size: 1.125rem;
  line-height: 1.563rem;
`};

  ${media.greaterThan("lg")`
  font-size: 1.25rem;
  line-height: 2.188rem;
`};
`;

export const ConferenceSubjectItem = styled.li`
  font-size: 1.25rem;
  line-height: 2.188rem;
  color: ${({ theme }) => theme.colors["text_secondary"]};
  font-style: italic;

  ${media.greaterThan("xs")`
font-size: 1.25rem;
`};

  ${media.greaterThan("sm")`
font-size: 1.125rem;
line-height: 1.563rem;
`};

  ${media.greaterThan("lg")`
font-size: 1.25rem;
line-height: 2.188rem;
`};
`;
