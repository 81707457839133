import React from "react"
import styled from "styled-components"

import media from "utils/media"
import { RichText } from "../RichTextRenderer"

const StyledSection = styled.div`
  display: grid;
  place-items: center;
  gap: 1.5rem;

  ${media.greaterThan("sm")`
    grid-template-columns: auto 1fr auto;
  `};
`

const StyledImage = styled.img`
  max-width: 150px;
  margin: 0;
`

export const ParagraphWithImages = ({ image_left, body, image_right }) => (
  <StyledSection>
    {image_left && <StyledImage src={image_left?.filename} alt={image_left?.alt} />}
    <RichText data={body} />
    {image_right && <StyledImage src={image_right?.filename} alt={image_right?.alt} />}
  </StyledSection>
)
