import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  ::-moz-selection {
    background-color: #002A58;
    color: white;
  }

  ::selection {
    background-color: #002A58;
    color: white;
  }

  html {
    min-height: 100vh;
    max-width: 100%;
    overflow-x: hidden;
  }

  body {
    font-size: 100%;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100%;
  }

  a {
    color: currentcolor;
    transition: color 0.25s;
    text-decoration: none;

    &:hover {
      color: #b01f09;
    }
  }

  p, a, li, span {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`
