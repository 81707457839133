import React from 'react'
import Components from './components.js'
import SEO from './SEO'

const Page = props => {
  const { blok, location } = props

  return (
    <>
      <SEO pageTitle={blok.seo_title} pageDescription={blok.seo_description} featureImage={blok.seo_image} />
      {blok.body &&
        blok.body.map(
          blok =>
            Components[blok.component] &&
            React.createElement(Components[blok.component], {
              key: blok._uid,
              blok,
              location,
            })
        )}
    </>
  )
}
export default Page
