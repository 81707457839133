import styled from "styled-components"
import media from "utils/media"

import { Container } from "components/Grid"

export const Section = styled(Container)`
  display: grid;
  width: 100%;

  ${media.greaterThan("md")`
    grid-template-columns: 1fr 1fr;
  `};
`