import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import media from "utils/media"

import RichTextRenderer from "components/Common/RichTextRenderer"

export const FooterWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:3rem;
`

export const FooterHolder = styled.div`
  width: calc(100% - 8.125rem);
  border-top: 1px solid ${({ theme }) => theme.colors["lightGrey"]};
  padding-top: 4.375rem;
  padding-bottom: 3.125rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
`

export const InfoColumn = styled.div`
  width: 25%;

  ${media.greaterThan("xs")`
    width: 90%;
  `};

  ${media.greaterThan("sm")`
    width: 25%;
  `};
`
export const ColumnTitle = styled.h4`
  font-size: 1.375rem;
  font-weight: 900;
  color: ${({ theme }) => theme.colors["darkBlue"]};
  margin-bottom: 1.063rem;
`

export const InfoParagraph = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors["darkGrey"]};
  margin-bottom: 1rem;
`

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const SingleInfo = styled.div`
  display: flex;
  margin-bottom: 1rem;

  img {
    width: 1.2rem;
    margin-bottom: 0;
    margin-right: 0.625rem;
    heigth: 1.188rem;
  }
  p {
    color: ${({ theme }) => theme.colors["darkGrey"]};
    margin-bottom: 0;
  }
`

export const LinksColumn = styled.div`
  flex: 0 1 300px;
  display: flex;
  flex-direction: column;

  ${media.greaterThan("xs")`
    margin-top: 2rem;
  `};

  ${media.greaterThan("sm")`
    margin-top: 0;
  `};

  ${media.greaterThan("md")`
    margin-top: 0;
  `};
`

export const SingleLink = styled(Link)`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors["darkGrey"]};
`

export const FooterBar = styled.div`
  background-color: ${({ theme }) => theme.colors["darkBlue"]};
  height: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-wight: 500;
  font-size: 1.2rem;

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    14% {
      transform: scale(1.3);
    }

    28% {
      transform: scale(1);
    }

    42% {
      transform: scale(1.3);
    }

    70% {
      transform: scale(1);
    }
  }

  & > div {
    display: grid;
    grid-template-columns: auto 20px auto auto;
    align-items: center;
    gap: 5px;
    cursor: default;

    & .icon {
      width: 20px;

      & path {
        fill: currentcolor;
        transition: fill 0.25s;
      }
    }

    &:hover .icon {
      animation: heartbeat 1300ms ease 0s infinite normal;

      & path {
        fill: #da3654;
      }
    }
  }
`

export const FooterLogo = styled(Img)`
  width: 6.5rem;
  margin-left: 0.7rem;
  margin-bottom: -10px;
  height: auto;
`

export const StyledRichText = styled(RichTextRenderer)`
  p {
    margin: 1rem 0;
    line-height: 150%;
  }
`

export const StyledDisclaimer = styled.div`
  padding: 0.8rem;
  font-size: 0.8rem;
  background: black;
  color: whitesmoke;

  & small {
    text-align: center;
    display: block;
  }

  & a {
    color: currentcolor;
    text-decoration: underline;
  }
`
