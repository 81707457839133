import React from 'react'
import styled from 'styled-components'
import media, { container } from 'utils/media'

const StyledBox = styled.div`
  max-width: 100%;
  display: block;
  padding: 0 16px;

  ${media.greaterThan('xs')`
    max-width: ${container.xs + 'rem'};
  `};

  ${media.greaterThan('sm')`
    padding: 0;
    max-width: ${container.sm + 'rem'};
  `};

  ${media.greaterThan('md')`
    max-width: ${container.md + 'rem'};
  `};

  ${media.greaterThan('lg')`
    max-width: ${container.lg + 'rem'};
  `};

  ${media.greaterThan('xl')`
    max-width: ${container.xl + 'rem'};
  `};
`

const Container = props => <StyledBox {...props} />

export default Container
