import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledImage = styled.img`
  display: block;
`

export const Image = props => (
  <StyledWrapper>
    <StyledImage {...props} />
  </StyledWrapper>
)
