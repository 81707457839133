import styled from 'styled-components'
import media from "utils/media";
import Img from 'gatsby-image'

export const PublicationCardWrapper = styled.a`
  width: 15rem;
  cursor: pointer;
  margin-bottom: 30px;
`

export const PublicationCardHeading = styled.div`
  border-left: 2px solid #412DDB;
  padding-left: 0.938rem;
`

export const PublicationCardTitle = styled.h3`
  font-size: 1.2rem;
  line-height: 2.5rem;
  font-weight: 800;
  color: ${({ theme }) => theme.colors['text_primary']};
  margin-bottom: 0.313rem;
`

export const PublicationCardSubTitle = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors['text_primary']};
  font-weight: 200;
  padding-bottom: 0.313rem;
  padding-top: 0;
`

export const PublicationImage = styled(Img)`
  width: auto;
  height: 360px;
  margin-top: 30px;
  border-radius: 19px;
`