import React from "react"
import SbEditable from "storyblok-react"

import theme from "utils/theme"

import { IconHeart } from "assets/icons"

import {
  FooterWrapper,
  FooterHolder,
  InfoColumn,
  ColumnTitle,
  InfoParagraph,
  CompanyInfo,
  SingleInfo,
  LinksColumn,
  SingleLink,
  FooterBar,
  FooterLogo,
  StyledRichText,
  StyledDisclaimer,
} from "./Footer.styles"

const BejemasLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="123.23px"
    height="30.662px"
    viewBox="0 0 1823.23 288.662"
    enable-background="new 0 0 1823.23 288.662"
    xmlSpace="preserve"
    fill="white"
    style={{ marginLeft: "5px", marginBottom: "-9px" }}
  >
    <g>
      <path
        d="M26.62,243.663c-15.011,0-26.62-11.258-26.62-24.57V27.647C0,13.658,12.29,3.075,26.62,3.075H84.3
		c51.185,0,76.78,32.765,76.78,66.881c0,23.892-15.354,38.56-35.83,48.463c21.157,8.523,42.318,23.889,42.318,53.563
		c0,39.941-32.085,71.684-84.633,71.684H26.62V243.663z M87.025,97.258c11.946,0,26.275-8.193,26.275-26.619
		c0-12.957-6.144-24.568-23.889-24.568H47.78v51.188H87.025L87.025,97.258z M47.781,140.941v59.369H86
		c22.524,0,33.791-11.25,33.791-29.01c0-19.453-13.648-30.359-31.742-30.359H47.781z"
      />
      <path
        d="M372.293,243.663h-90.436c-16.721,0-31.729-5.805-31.729-24.229V27.303c0-11.939,11.253-24.229,26.276-24.229h92.476
		c12.975,0,23.89,10.24,23.89,22.181c0,12.292-10.915,21.159-23.89,21.159h-70.977v54.941h64.499
		c11.94,0,23.549,9.209,23.549,21.502c0,12.277-10.245,21.839-23.549,21.839h-64.499v55.613h74.39
		c12.632,0,23.891,9.561,23.891,21.172C396.184,233.774,385.271,243.663,372.293,243.663z"
      />
      <path
        d="M579.079,163.464c0,51.861-18.422,83.271-79.164,83.271c-12.975,0-39.254-1.365-39.254-23.547
		c0-13.656,12.631-21.516,22.182-21.516c7.854,0,12.293,2.053,20.816,2.053c22.861,0,27.645-15.354,27.645-40.264V21.837
		C531.303,9.901,541.544,0,555.195,0c12.618,0,23.885,10.243,23.885,21.838L579.079,163.464L579.079,163.464z"
      />
      <path
        d="M1139.74,225.235c0,11.6-10.93,21.5-23.553,21.5c-12.961,0-24.229-9.9-24.229-21.5V107.83l-38.9,76.445
		c-4.438,8.863-12.977,12.961-22.523,12.961c-9.219,0-17.744-4.098-22.184-12.961l-38.9-76.445v117.406
		c0,11.6-11.268,21.5-24.57,21.5c-12.977,0-23.207-9.9-23.207-21.5V22.524c0-12.963,10.572-22.521,24.57-22.521
		c10.916,0,19.451,4.438,23.891,13.313l60.74,120.456l60.742-120.796C1096.4,3.075,1105.605,0,1115.164,0
		c13.99,0,24.576,10.243,24.576,22.522V225.235L1139.74,225.235z"
      />
      <path
        d="M1409.301,217.384l-66.543-194.178C1337.977,9.56,1331.148,0,1314.771,0s-23.203,9.901-27.646,23.205l-66.543,194.178
		c-0.682,1.709-1.014,5.807-1.014,7.514c0,12.963,11.256,21.84,24.563,21.84c8.193,0,19.119-6.146,22.521-16.035
		c0,0,46.754-154.6,48.119-165.179c1.365,10.579,47.781,165.179,47.781,165.179c3.412,9.551,14.326,16.035,23.201,16.035
		c13.99,0,24.574-8.877,24.574-21.84C1410.328,223.19,1409.986,219.093,1409.301,217.384z"
      />
      <path
        d="M843.892,217.384L777.346,23.206C772.563,9.56,765.738,0,749.363,0c-16.38,0-23.209,9.901-27.651,23.205l-66.543,194.178
		c-0.685,1.709-1.014,5.807-1.014,7.514c0,12.963,11.255,21.84,24.56,21.84c8.195,0,19.12-6.146,22.525-16.035
		c0,0,46.752-154.6,48.123-165.179c1.362,10.579,47.776,165.179,47.776,165.179c3.414,9.551,14.326,16.035,23.201,16.035
		c13.988,0,24.573-8.877,24.573-21.84C844.914,223.19,844.576,219.093,843.892,217.384z"
      />
      <path
        d="M1580.252,150.825c-7.17-3.061-15.693-6.129-24.912-9.202c-33.438-10.244-69.619-21.5-69.619-70.984
		c0-44.019,30.715-70.639,79.18-70.639c20.133,0,68.582,5.121,68.582,30.718c0,11.257-8.865,21.499-23.879,21.499
		c-6.145,0-10.926-2.049-16.729-3.754c-6.479-2.394-13.986-5.121-25.928-5.121c-16.729,0-32.426,5.803-32.426,25.931
		c0,15.011,8.537,20.134,14.332,22.864c8.875,4.439,17.068,7.17,24.572,9.219c33.447,10.574,69.961,22.182,69.961,70.625
		c0,47.453-36.172,74.756-84.977,74.756c-25.254,0-73.033-8.877-73.033-32.779c0-10.916,9.902-21.5,22.523-21.5
		c5.123,0,10.584,1.707,17.063,4.1c8.193,2.732,19.119,6.83,33.789,6.83c23.549,0,35.488-10.588,35.488-29.355
		C1594.242,158.677,1586.396,153.56,1580.252,150.825z"
      />
      <path
        d="M1799.682,245.325c11.953,0,23.549,9.551,23.549,21.84c0,12.281-10.229,21.498-23.549,21.498h-90.879
		c-13.303,0-23.549-9.217-23.549-21.498c0-12.289,11.611-21.84,23.549-21.84H1799.682z"
      />
    </g>
  </svg>
)

export default function Footer({ data }) {
  return (
    <SbEditable content={data}>
      <FooterWrapper>
        <FooterHolder theme={theme}>
          {(data?.columns || []).map(column => (
            <LinksColumn>
              <ColumnTitle>{column.title}</ColumnTitle>
              <StyledRichText content={column.description} />
              {(column.links || []).map(link => (
                <SingleLink to={link.url}>{link.label}</SingleLink>
              ))}
            </LinksColumn>
          ))}
        </FooterHolder>

        <FooterBar theme={theme}>
          <div>
            With
            <IconHeart className="icon" />
            by
            <a href="https://bejamas.io/" title="Jamstack Developers" target="_blank">
              <BejemasLogo />
            </a>
          </div>
        </FooterBar>

        <StyledDisclaimer>
          <small>
            Wroclaw University of Science and Technology (WUST) is the main organizer of conferences
            and other events. Therefore, when you interact with WUST you are providing personal data
            about yourself. The kinds of personal data that WUST collects and holds can include
            name, address, email, phone, membership status, dietary or special access requests,
            accommodation booking details, credit card information for hotel bookings and any other
            data required by the event. data.
          </small>
          <small>
            <a href="https://pwr.edu.pl/fcp/TGBUKOQtTKlQhbx08SlkTUhZeUTgtCgg9ACFDC0RBSnxBG1gnBVcoFW8SETZKHg/_users/code_zAlkeOR5cJRk2WBUqERdeDV0HGG14XR0VG2sEVRNZCiE/private_policy_wust.pdf">
              This "Privacy Policy of Wrocław University of Science and Technology"
            </a>{" "}
            is to inform you about when and how WUST may collect your data, and what we use
            it for. It will also provide you with the details you need to let us know if you want us
            to correct or forget your data.
          </small>
        </StyledDisclaimer>
      </FooterWrapper>
    </SbEditable>
  )
}
