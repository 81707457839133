import React, { useMemo } from 'react'

import { Container } from "../Grid";
import { ConferenceDetailsWrapper, ConferenceDetailsTitle, ConferenceDetailsSubTitle, StyledRichText, OrganizedTitle, OrganizersText, OrganizersImage } from './ConferenceDetailsSection.styles'
import theme from '../../utils/theme'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

function ConferenceDetailsSection({ blok }) {

  const getImageFluid = (image, width) => image && useMemo(() => getFluidGatsbyImage(image, { maxWidth: width }), [])

  return (
    <Container>
      <ConferenceDetailsWrapper>
        <ConferenceDetailsTitle theme={theme}>{blok.conference_title}</ConferenceDetailsTitle>
        <ConferenceDetailsSubTitle theme={theme}>{blok.conference_subtitle}</ConferenceDetailsSubTitle>
        <StyledRichText theme={theme} content={blok.locations} />
        <OrganizedTitle>Organized by:</OrganizedTitle>
        {blok.organized_by.length > 0 && blok.organized_by.map(organizer => (
          organizer.component == 'text' ? (
            <OrganizersText key={organizer._uid}>{organizer.text}</OrganizersText>
          ) : (
            <OrganizersImage style={{ width: `${organizer.width && organizer.width}px` }} key={organizer._uid} fluid={getImageFluid(organizer.image, organizer.width)} />
          )
        ))}
        <OrganizedTitle>Co-organized by:</OrganizedTitle>
        {blok.co_organized_by.length > 0 && blok.co_organized_by.map(organizer => (
          organizer.component == 'text' ? (
            <OrganizersText key={organizer._uid}>{organizer.text}</OrganizersText>
          ) : (
            <OrganizersImage style={{ width: `${organizer.width && organizer.width}px` }} key={organizer._uid} fluid={getImageFluid(organizer.image, organizer.width)} />
          )
        ))}
      </ConferenceDetailsWrapper>
    </Container>
  )
}

export default ConferenceDetailsSection
