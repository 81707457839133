import React from "react";
import styled from "styled-components";
import StoryblokClient from "storyblok-js-client";
import {
  render,
  NODE_IMAGE,
  MARK_STYLED,
} from "storyblok-rich-text-react-renderer";

import SectionTwoColumns from "components/SectionTwoColumns";

import { Image } from "./RichTextElements/Image";
import { LinkToPDF } from "./RichTextElements/LinkToPDF";
import { Lightbox } from "./RichTextElements/Lightbox";
import { ParagraphWithImages } from "./RichTextElements/ParagraphWithImages";
import { Table } from "./RichTextElements/Table";
import { Map } from "./RichTextElements/Map";

export default function RichTextRenderer({ content, ...props }) {
  let Storyblok = new StoryblokClient({
    accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
  });

  const rich_text = Storyblok.richTextResolver.render(content);

  return <div dangerouslySetInnerHTML={{ __html: rich_text }} {...props}></div>;
}

export const RichText = ({ data, ...rest }) =>
  data ? (
    <div {...rest}>
      {render(data, {
        markResolvers: {
          [MARK_STYLED]: (children, props) => {
            return <span style={{ color: `${props.class}` }}>{children}</span>;
          },
        },
        nodeResolvers: {
          [NODE_IMAGE]: (_, props) => <Image {...props} />,
        },
        blokResolvers: {
          ["element_link"]: (props) => <LinkToPDF {...props} />,
          ["element_table"]: (props) => <Table {...props} />,
          ["element_images"]: (props) => <Lightbox {...props} />,
          ["element_paragraph_with_images"]: (props) => (
            <ParagraphWithImages {...props} />
          ),
          ["element_map"]: (props) => <Map {...props} />,
          ["section_two_column_layout"]: (props) => (
            <SectionTwoColumns blok={props} />
          ),
        },
      })}
    </div>
  ) : (
    <div />
  );

export const StyledRichText = styled(RichText)`
  > :is(h1, h2, h3, h4) {
    margin: 0;
    margin-top: 3rem;
    margin-bottom: 1rem;
    color: #0b2545;
  }

  * + :is(h1, h2, h3, h4) {
    margin-top: 3rem;
  }

  > :is(p, ol, ul) {
    color: #616161;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    margin: 1rem 0;
    line-height: 150%;
  }

  ul,
  ol {
    margin-bottom: 0;

    li,
    li p {
      margin: 0;
    }
  }
`;
