import { Link } from "gatsby";
import styled from "styled-components";

export const DrawerContainer = styled.div`
  position: absolute;
  width: 0;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: white;
  transition: width 0.4s ease-in-out;
  display: flex;
  align-items: top;
  z-index: 900;
  overflow: hidden;
  padding-top: 7.5rem;
`;

export const MenuHolder = styled.div`
  padding-left: 1.25rem;
  display: flex;
  flex-direction: column;
`

export const MenuLink = styled(Link)`
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors["offBlack"]};

  &.active {
    color: #b01f09;
  }
`