import styled from 'styled-components'
import media from "utils/media";
import Img from 'gatsby-image'

import RichTextRenderer from '../Common/RichTextRenderer'

export const ConferenceDetailsWrapper = styled.div`
  margin-top: 5rem;
  margin-bottom: 5.625rem;

  ${media.greaterThan("xs")`
    width: 90%;
    margin-left: 5%;
  `};

  ${media.greaterThan("sm")`
    width: 90%;
  `};

  ${media.greaterThan("md")`
    width: 70%;
    margin-left: 15%;
  `};

  ${media.greaterThan("lg")`
    width: 60%;
    margin-left: 20%;
  `};
`

export const ConferenceDetailsTitle = styled.h3`
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
  color: ${({ theme }) => theme.colors['text_primary']};
  margin-bottom: 2.188rem;
`

export const ConferenceDetailsSubTitle = styled.p`
  font-size: 1.375rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors['darkBlue']};
  margin-bottom: 2.5rem;
`

export const StyledRichText = styled(RichTextRenderer)`
  margin-top: 2.5rem;
  
  p {
    line-height: 1.875rem;
    color: ${({ theme }) => theme.colors['text_secondary']};
  }
`

export const OrganizedTitle = styled.p`
  margin-top: 2.5rem;
  color: ${({ theme }) => theme.colors['text_secondary']};
  font-weight: bold;
`

export const OrganizersText = styled.p`
  margin-top: 2.5rem;
  color: ${({ theme }) => theme.colors['text_secondary']};
  font-style: italic;
`

export const OrganizersImage = styled(Img)`
  width: auto;
  height: auto;
  margin-top: 0.4rem;
`