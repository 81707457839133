import React from "react"
import styled from "styled-components"
import { SRLWrapper } from "simple-react-lightbox"

import media from "utils/media"

const StyledImage = styled.img`
  margin: 0;
  padding: 1rem;
  width: 100%;

  ${media.greaterThan("sm")`
    width: 50%;
  `};

  ${media.greaterThan("md")`
    width: 25%;
  `};
`

export const Lightbox = ({ images = [] }) => (
  <SRLWrapper>
    {images.map(item => (
      <a href={item?.image?.filename}>
        <StyledImage
          src={item?.thumbnail?.filename || item?.image?.filename}
          alt={item?.thumbnail?.alt || item?.image?.alt}
        />
      </a>
    ))}
  </SRLWrapper>
)
