import React, { useMemo } from "react"
import SbEditable from "storyblok-react"
import { getFluidGatsbyImage } from "gatsby-storyblok-image"

import theme from "utils/theme"

import { Container } from "components/Grid"
import PublicationCard from "components/PublicationCard/PublicationCard"

import {
  PublicationsSubTitle,
  PublicationTitle,
  PublicationsWrapper,
  PublicationsList,
} from "./Publications.styles"

export default function Publications({ blok }) {
  const getImageFluid = image =>
    image && useMemo(() => getFluidGatsbyImage(image, { maxWidth: 300 }), [])

  return (
    <SbEditable content={blok}>
      <Container>
        <PublicationsWrapper>
          <PublicationTitle theme={theme}>{blok.title}</PublicationTitle>
          <PublicationsSubTitle>{blok.subtitle}</PublicationsSubTitle>

          <PublicationsList>
            {blok.publications.length > 0 &&
              blok.publications.map(publication => (
                <PublicationCard
                  key={publication._uid}
                  title={publication.title}
                  subTitle={publication.subtitle}
                  image={getImageFluid(publication.image)}
                  href={publication.link.url}
                />
              ))}
          </PublicationsList>
        </PublicationsWrapper>
      </Container>
    </SbEditable>
  )
}
