import React from "react"
import styled, { css } from "styled-components"

import { IconPDF } from "assets/icons"

const StyledWrapper = styled.div`
  display: flex;

  ${({ variant }) =>
    variant === "button" &&
    css`
      justify-content: center;
      color: white;

      a {
        display: flex;
        background-color: #0b2545;
        padding: 1rem 2rem;
        transition: box-shadow 0.25s;

        :hover {
          color: white;
          box-shadow: 0px 0px 10px #0b2545;
        }

        svg {
          display: none;
        }
      }
    `};
`

const StyledAnchorLink = styled.a`
  display: grid;
  grid-template-columns: 20px auto;
  gap: 6px;
  transition: color 0.25s;

  &:hover {
    color: #b01f09;
  }
`

export const LinkToPDF = ({ label, url, variant }) => (
  <StyledWrapper variant={variant}>
    <StyledAnchorLink href={url} title={label} rel="noopener noreferrer" target="_blank">
      <IconPDF />
      <span>{label}</span>
    </StyledAnchorLink>
  </StyledWrapper>
)
