import React, { useMemo } from "react"
import SbEditable from "storyblok-react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-storyblok-image"

import theme from "utils/theme"

import { Container } from "components/Grid"

import {
  PurposeWrapper,
  ConferenceImageHolder,
  ConferencalPurpose,
  ConferenceTitle,
  ConferenceParagraph,
  ConferenceSubjects,
  RegisterButton,
} from "./ConferencePurpose.styles"

export default function ConferencePurpose({ blok }) {
  const getImageFluid = image =>
    image && useMemo(() => getFluidGatsbyImage(image, { maxWidth: 500 }), [])

  return (
    <SbEditable content={blok}>
      <Container>
        <PurposeWrapper>
          <ConferencalPurpose>
            <ConferenceTitle>{blok.title}</ConferenceTitle>
            {blok.conference_description.length > 0 &&
              blok.conference_description.map(paragraph => (
                <ConferenceParagraph key={paragraph._uid} theme={theme}>
                  {paragraph.content}
                </ConferenceParagraph>
              ))}
            <ConferenceSubjects content={blok.conference_subjects} theme={theme} />
            {blok.cta.length > 0 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <RegisterButton href={blok.cta[0].url && blok.cta[0].url.url} theme={theme}>
                  {blok.cta[0].text}
                </RegisterButton>
              </div>
            )}
          </ConferencalPurpose>
          <ConferenceImageHolder as={Img} fluid={getImageFluid(blok.image)} />
        </PurposeWrapper>
      </Container>
    </SbEditable>
  )
}
