import React from "react"
import styled from "styled-components"

import media from "utils/media"

const StyledWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 1rem;
  }

  iframe {
    width: 100%;
  }
`

export const Map = ({ src }) => (
  <StyledWrapper>
    <iframe src={src} width="800" height="500" frameborder="0" allowfullscreen="" />
  </StyledWrapper>
)
