import styled from "styled-components"
import media from "utils/media"
import Img from "gatsby-image"
import RichTextRenderer from "../Common/RichTextRenderer"

export const PreviousWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 2.5rem;

  ${media.greaterThan("xs")`
  width: 100%;
  padding-left: 1.563rem;
  padding-right: 1.563rem;
  flex-direction: column;
`};

  ${media.greaterThan("sm")`
  flex-direction: row;
  padding-left: 1.75rem;
  padding-right: 0;
  align-items: center;
`};

  ${media.greaterThan("md")`
  flex-direction: row;
  padding-left: 3.875rem;
  align-items: center;
`};
`

export const SectionTitle = styled.h3`
  font-size: 2.5rem;
  margin-bottom: 2.5rem;
  text-align: center;
`

export const PreviousConferencesCarousel = styled.div`
  width: 45%;

  ${media.greaterThan("xs")`
  width: 100%;
`};

  ${media.greaterThan("sm")`
  width: 50%;
  height: auto;
`};

  ${media.greaterThan("lg")`
  width: 53%;
`};
`

export const PreviousConferencesHolder = styled.div`
  width: 50%;

  ${media.greaterThan("xs")`
  width: 100%;
`};

  ${media.greaterThan("sm")`
  width: 47%;
`};

  ${media.greaterThan("lg")`
  width: 45%;
`};
`

export const ConferenceTitle = styled.h3`
  font-size: 2.5rem;
`

export const ConferenceParagraph = styled.p`
  font-size: 1.25rem;
  line-height: 2.188rem;
  color: ${({ theme }) => theme.colors["text_primary"]};
  font-weight: 500;
  margin-bottom: 1.875rem;

  ${media.greaterThan("xs")`
    font-size: 1.25rem;
  `};

  ${media.greaterThan("sm")`
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
    line-height: 1.563rem;
  `};

  ${media.greaterThan("lg")`
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    line-height: 2.188rem;
  `};
`

export const ConferenceSubjects = styled(RichTextRenderer)`
  font-size: 1.25rem;
  line-height: 2.188rem;
  color: ${({ theme }) => theme.colors["text_secondary"]};
  font-style: italic;
  margin-bottom: 1.25rem;

  p {
    line-height: 1.5rem;
  }

  ${media.greaterThan("xs")`
    font-size: 1.25rem;
  `};

  ${media.greaterThan("sm")`
    font-size: 1.125rem;
    line-height: 1.563rem;
  `};

  ${media.greaterThan("lg")`
    font-size: 1.25rem;
    line-height: 2.188rem;
  `};
`

export const ConferenceSubjectsItem = styled.li`
  font-size: 1.25rem;
  line-height: 2.188rem;
  color: ${({ theme }) => theme.colors["text_secondary"]};
  font-style: italic;
  margin-bottom: 1.25rem;

  ${media.greaterThan("xs")`
  font-size: 1.25rem;
`};

  ${media.greaterThan("sm")`
  font-size: 1.125rem;
  line-height: 1.563rem;
`};

  ${media.greaterThan("lg")`
  font-size: 1.25rem;
  line-height: 2.188rem;
`};
`

export const ExploreButton = styled.a`
  background-color: ${({ theme }) => theme.colors["darkBlue"]};
  color: white;
  font-size: 1;
  padding: 1.25rem 2.5rem;
  display: inline-block;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: box-shadow 0.25s;

  :hover {
    color: white;
    box-shadow: 0px 0px 10px #0b2545;
  }

  ${media.greaterThan("xs")`
    margin-bottom: 1.875rem;
  `};

  ${media.greaterThan("md")`
    margin-bottom: 0;
  `};
`

export const CarouselDot = styled.div`
  width: 0.688rem;
  height: 0.688rem;
  border-radius: 50%;
  margin-left: 1.25rem;
  margin-top: 0.9rem;
  background-color: ${({ active }) => (active ? "#0B2545" : "#d8d8d8")};
  cursor: pointer;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: -8px;
    left: -8px;
    width: 1.688rem;
    height: 1.688rem;
    border: 1px ${({ active }) => (active ? "#0B2545" : "transparent")} solid;
    border-radius: 50%;
  }
`

export const CarouselImageInfo = styled.div`
  position: absolute;
  bottom: 1.563rem;
  width: 14.375rem;
  height: 8.75rem;
  left: 1.563rem;
  background: rgba(255, 255, 255, 0.72);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #616161;
    margin-bottom: 0.8rem;
  }
  h6 {
    color: #3a3a3a;
    font-weight: bold;
    font-size: 1.375rem;
  }

  ${media.greaterThan("xs")`
    width: 90%;
    left: 5%;
  `};

  ${media.greaterThan("sm")`
    width: 90%;
    left: 5%;
  `};

  ${media.greaterThan("md")`
    width: 14.375rem;
    left: 1.563rem;
  `};
`
