import React from 'react'

import { SingleDateContainer } from './SingleDate.styles'
import theme from 'utils/theme';

function SingleDate(props) {
  const { date, info } = props

  return (
    <SingleDateContainer theme={theme}>
      <span>{date} - </span>
      {info}
    </SingleDateContainer>
  )
}

export default SingleDate
