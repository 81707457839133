import React from 'react'
import Helmet from 'react-helmet'

const SEO = ({ pageTitle, pageDescription, featureImage }) => (
  <Helmet>
    <title>{pageTitle}</title>
    <meta name="description" content={pageDescription} />
    <meta name="referrer" content="origin" />
    <meta name="image" content={featureImage} />

    {/* OpenGraph tags */}
    <meta property="og:title" content={pageTitle} />
    <meta property="og:description" content={pageDescription} />
    <meta property="og:image" content={featureImage} />
  </Helmet>
)

export default SEO
