import React from "react"
import styled from "styled-components"
import media from "utils/media"

import { RichText } from "../RichTextRenderer"

const StyledTable = styled.table`
  padding: 1rem;

  & td {
    padding: 1rem;
  }

  & td div p {
    margin: 0;
  }
`

const StyledTableHead = styled.th`
  border: none;
  border-bottom: 1px solid lightgray;
  text-align: center;
`

const StyledTableRow = styled.tr`
  display: grid;

  ${media.greaterThan("sm")`
    display: table-row;
  `};
`

const StyledLabel = styled.td`
  text-align: left;
  border: none;

  ${media.greaterThan("sm")`
    border-bottom: 1px solid lightgray;
  `};
`

const StyledValue = styled.td`
  text-align: right;
`

export const Table = ({ title, rows = [] }) => (
  <div>
    <StyledTable>
      {title && (
        <tr>
          <StyledTableHead colSpan="2">{title}</StyledTableHead>
        </tr>
      )}
      {rows.map(row => (
        <StyledTableRow key={row._uid}>
          <StyledLabel>
            <RichText data={row.label} />
          </StyledLabel>
          <StyledValue>
            <RichText data={row.value} />
          </StyledValue>
        </StyledTableRow>
      ))}
    </StyledTable>
  </div>
)
