import React from "react"
import SbEditable from "storyblok-react"

import theme from "utils/theme"

import { Container } from "components/Grid"

import {
  InfoWrapper,
  ConferenceInfoTitle,
  InfoHolder,
  SingleInfo,
  InfoTitle,
  InfoParagraph,
} from "./InfoSection.styles"

export default function InfoSection({ blok }) {
  return (
    <SbEditable content={blok}>
      <Container>
        <InfoWrapper>
          <ConferenceInfoTitle theme={theme}>
            {blok.section_heading}
            <small>{blok.section_description}</small>
          </ConferenceInfoTitle>
          <InfoHolder>
            <h4>Organized by:</h4>
            <div>
              {blok.infos.length > 0 &&
                blok.infos.map(info => (
                  <SingleInfo key={info._uid}>
                    <InfoTitle>{info.title}</InfoTitle>
                    <InfoParagraph>{info.description}</InfoParagraph>
                  </SingleInfo>
                ))}
            </div>
          </InfoHolder>
        </InfoWrapper>
      </Container>
    </SbEditable>
  )
}
