import styled from "styled-components";
import media from "utils/media";
import Img from "gatsby-image";

import RichTextRenderer from '../Common/RichTextRenderer'

export const PurposeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 2.5rem 0;

  ${media.greaterThan("xs")`
    width: 100%;
    padding-left: 1.563rem;
    padding-right: 1.563rem;
    flex-direction: column;
  `};

  ${media.greaterThan("sm")`
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  `};

  ${media.greaterThan("md")`
    flex-direction: row;
  `};
`;

export const ConferenceImageHolder = styled(Img)`
  width: 45%;

  ${media.greaterThan("xs")`
    width: 100%;
  `};

  ${media.greaterThan("sm")`
    width: 50%;
    height: auto;
    object-fit: contain;
  `};

  ${media.greaterThan("lg")`
    width: 45%;
  `};
`;

export const ConferencalPurpose = styled.div`
  width: 50%;

  ${media.greaterThan("xs")`
    width: 100%;
  `};

  ${media.greaterThan("sm")`
    width: 47%;
  `};

  ${media.greaterThan("lg")`
    width: 50%;
  `};
`;

export const ConferenceTitle = styled.h3`
  font-size: 2.5rem;
`;

export const ConferenceParagraph = styled.p`
  font-size: 1.25rem;
  line-height: 2.188rem;
  color: ${({ theme }) => theme.colors['text_primary']};
  font-weight: 500;
  margin-bottom: 1.875rem;

  ${media.greaterThan("xs")`
    font-size: 1.25rem;
  `};

  ${media.greaterThan("sm")`
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
    line-height: 1.563rem;
  `};

  ${media.greaterThan("lg")`
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    line-height: 2.188rem;
  `};
`;

export const ConferenceSubjects = styled(RichTextRenderer)`
  font-size: 1.25rem;
  line-height: 2.188rem;
  color: ${({ theme }) => theme.colors["text_secondary"]};
  font-style: italic;
  margin-bottom: 1.25rem;

  p {
    line-height: 2.188rem;
  }

  ${media.greaterThan("xs")`
    font-size: 1.25rem;
  `};

  ${media.greaterThan("sm")`
    font-size: 1.125rem;
    p {
      line-height: 1.563rem;
    }
  `};

  ${media.greaterThan("lg")`
    font-size: 1.25rem;
    p {
      line-height: 2.188rem;
    }
  `};
`;

export const RegisterButton = styled.a`
  background-color: ${({ theme }) => theme.colors["darkBlue"]};
  color: white;
  font-size: 1;
  display: inline-block;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  width: 21.875rem;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: box-shadow 0.25s;

  :hover {
    color: white;
    box-shadow: 0px 0px 10px #0b2545;
  }

  ${media.greaterThan("xs")`
    margin-bottom: 1.875rem;
  `};

  ${media.greaterThan("md")`
    margin-bottom: 0;
  `};
`;
