import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import { theme } from 'utils'

function MyLink({ blok, ...props }) {
  return !_isEmpty(_get(blok, 'url.url')) ? (
    <StyledLink href={blok.url.url} target="_blank" {...props}>
      {props.children || blok.text}
    </StyledLink>
  ) : (
    <StyledLink as={Link} to={'/' + blok.url.cached_url} {...props}>
      {props.children || blok.text}
    </StyledLink>
  )
}

export default React.memo(MyLink)

const StyledLink = styled.a`
  ${({ theme, type }) => type && theme.buttons[type]}
  ${({ theme, size }) => size && theme.buttonsSize[size]}
  border-color: ${({ borderColor, theme }) =>
    borderColor ? theme.colors[borderColor] || borderColor : theme.colors.mustard};
`

MyLink.propTypes = {
  type: PropTypes.oneOf(Object.keys(theme.buttons)),
  size: PropTypes.oneOf(Object.keys(theme.buttonsSize)),
  css: PropTypes.string,
  borderColor: PropTypes.oneOf(Object.keys(theme.colors)),
}
