import React, { useMemo } from "react"
import SbEditable from "storyblok-react"
import { getFluidGatsbyImage } from "gatsby-storyblok-image"

import theme from "utils/theme"

import { Container } from "components/Grid"

import {
  ClientsSectionTitle,
  ClientsWrapper,
  ClientsSectionSubTitle,
  LogosHolder,
  LogoColumn,
  LogoCard,
  LogoImage,
} from "./Clients.styles"

export default function Clients({ blok }) {
  const getImageFluid = image =>
    image && useMemo(() => getFluidGatsbyImage(image, { maxWidth: 200 }), [])

  return (
    <SbEditable content={blok}>
      <Container>
        <ClientsWrapper>
          <ClientsSectionTitle theme={theme}>{blok.title}</ClientsSectionTitle>
          <ClientsSectionSubTitle theme={theme}>{blok.subtitle}</ClientsSectionSubTitle>

          <LogosHolder>
            {blok.clients.length > 0 &&
              blok.clients.map(clientCol => (
                <LogoColumn key={clientCol._uid}>
                  {clientCol.rows.length > 0 &&
                    clientCol.rows.map(client => (
                      <LogoCard key={client._uid}>
                        <LogoImage fluid={getImageFluid(client.image)} />
                      </LogoCard>
                    ))}
                </LogoColumn>
              ))}
          </LogosHolder>
        </ClientsWrapper>
      </Container>
    </SbEditable>
  )
}
