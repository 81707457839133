import styled from "styled-components";

import media from "utils/media";

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 5.5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: white;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  ${media.greaterThan("xs")`
    justify-content: space-between;
  `};

  ${media.greaterThan("sm")`
    justify-content: space-between;
  `};

  ${media.greaterThan("md")`
    justify-content: space-between;
  `};

  ${media.greaterThan("lg")`
    justify-content: left;
  `};
`;

export const HeaderPlaceholder = styled.div`
  height: 5.5rem;
`;

export const HeaderLogo = styled.img`
  z-index: 980;
  width: 5.3rem;
  margin: 0;
`;

export const MenuHolder = styled.ul`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  margin: 0;
  color: ${({ theme }) => theme.colors["offBlack"]};

  ${media.greaterThan("xs")`
    display: none;
  `};

  ${media.greaterThan("sm")`
    display: none;
  `};

  ${media.greaterThan("md")`
    display: none;
  `};

  ${media.greaterThan("lg")`
    display: flex;
  `};
`;

export const MenuLink = styled.li`
  font-size: 0.85rem;
  padding-right: 0.8rem;
  border-right: 1px solid ${({ theme }) => theme.colors["lightGrey"]};
  margin-left: 0.938rem;
  color: ${({ theme }) => theme.colors["offBlack"]};
  font-weight: 500;
  text-decoration: none;

  &:last-child {
    border: none;
  }

  &.active {
    color: #b01f09;
  }
`;
export const MenuIcon = styled.div`
  ${media.greaterThan("xs")`
    display: flex;
  `};

  ${media.greaterThan("sm")`
    display: flex;
  `};

  ${media.greaterThan("md")`
    display: flex;
  `};

  ${media.greaterThan("lg")`
    display: none;
  `};

  &.open {
    span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 0.65rem;
      left: 0;
    }
    span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }
    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 0.625rem;
      left: 0;
    }
  }

  width: 1.5rem;
  height: 1.25rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 999;

  span {
    display: block;
    position: absolute;
    height: 0.125rem;
    width: 100%;
    background: #949ea0;
    border-radius: 0.375rem;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    top: 0;
  }
  span:nth-child(2) {
    top: 0.5rem;
    right: 0;
  }
  span:nth-child(3) {
    top: 1rem;
  }
`;
