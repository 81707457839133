import { css } from 'styled-components'
import { breakpoints } from './media'

const colors = {
  blueDark: '#002A58',
  blueLight: '#78ADCA',
  neutral: '#e9e8e7',
  dark: '#4a4a4a',
  mustard: '#fdb813',
  mustardDarker: '#f0ac0a',
  green: '#a7ba00',
  orange: '#ff6d35',
  aqua: '#01b2ab',
  purple: '#8073ca',
  success: '#a7ba00',
  information: '#78adca',
  warning: '#ff6d35',
  error: '#ff3129',
  navy: '#022245',
  lightPink: '#f3f2f2',
  greyishBrown: '#4a4a4a',
  text_primary: '#18191F',
  text_secondary: '#898989',
  darkBlue: '#0B2545',
  lightGrey: '#D8D8D8',
  darkGrey: '#616161',
  offBlack: '#303539',
}

const buttonPrimary = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  background: ${colors.mustard};
  transition: all 0.25s ease;

  &:hover {
    background-color: ${colors.mustardDarker};
  }

  &:focus,
  &:active {
    border: solid 3px #e7a507;
    background-color: ${colors.mustardDarker};
  }

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`

const buttonSecondary = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  background-color: ${colors.blueDark};
  transition: all 0.25s ease;

  &:hover {
    background-color: ${colors.navy};
  }

  &:focus,
  &:active {
    border: solid 3px #01162c;
    background-color: ${colors.navy};
  }

  &:disabled {
    color: ${colors.greyishBrown};
    opacity: 0.25;
    cursor: not-allowed;
  }
`

const buttonTertiary = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  color: ${colors.blueDark};
  background-color: #fff;
  transition: all 0.25s ease;

  &:hover {
    background-color: ${colors.lightPink};
  }

  &:focus,
  &:active {
    border: solid 3px #01162c;
    background-color: #fff;
  }

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`

const buttonLight = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  color: #fff;
  background-color: ${colors.blueLight};
  transition: all 0.25s ease;

  &:hover {
    background-color: #5e879e;
  }

  &:focus,
  &:active {
    border: solid 3px #01162c;
    background-color: #fff;
  }

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`

const textLink = css`
  display: flex;
  height: 63px;
  width: 187px;
  align-items: flex-end;
  border-top: 6px solid ${colors.mustard};
  font-size: 16px;
  position: relative;
  text-decoration: none;
  color: ${colors.navy};

  > img {
    margin-left: auto !important;
    margin-bottom: 7px !important;
  }
`

const buttonStandard = css`
  width: 169px;
  height: 40px;
`
const buttonSmall = css`
  width: 75px;
  height: 40px;
  font-size: 12px !important;
`

export default {
  // Colors
  colors,

  // default styles
  buttons: {
    standard: {},
    primary: buttonPrimary,
    secondary: buttonSecondary,
    tertiary: buttonTertiary,
    light: buttonLight,
    textLink: textLink,
  },
  buttonsSize: {
    small: buttonSmall,
    standard: buttonStandard,
  },
  textLink,

  // GRID
  space: [0, 5, 10, 15, 24],
  breakpoints: Object.values(breakpoints).map((breakpoint) => breakpoint + 'em'),
}
