import React from "react"
import SbEditable from "storyblok-react"

import { Container } from "components/Grid"
import SingleDate from "components/SingleDate/SingleDate"

import { ImportantDatesWrapper, ImportantDatesTitle } from "./ImportantDates.styles"

function ImportantDatesSection({ blok }) {
  return (
    <SbEditable content={blok}>
      <Container>
        <ImportantDatesWrapper>
          <ImportantDatesTitle>{blok.title}</ImportantDatesTitle>

          {blok.important_dates.length > 0 &&
            blok.important_dates.map(im_date => (
              <SingleDate key={im_date._uid} date={im_date.date} info={im_date.info} />
            ))}
        </ImportantDatesWrapper>
      </Container>
    </SbEditable>
  )
}

export default ImportantDatesSection
